import React from 'react';
import { Option } from '../../models/searchAutocomplete';

import { Container, CustomSelect } from './styles';

interface Props {
  dark?: boolean;
  placeholder: string;
  loadOptions: (inputValue: string) => Promise<Option[]>;
  onChange:(option: Option) => void;
}

const SearchAutocomplete: React.FC<Props> = ({ dark, placeholder, loadOptions, onChange }) => {
  return (
    <Container>
      <CustomSelect
        instanceId="react-select"
        classNamePrefix="react-select"
        placeholder={placeholder}
        maxMenuHeight={200}
        noOptionsMessage={() => 'Não encontrado'}
        isClearable
        onChange={(option: Option) => option && onChange(option)}
        loadOptions={loadOptions}
        loadingMessage={() => 'Carregando...'}
        components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
        dark={dark}
      />
    </Container>
  );
};

export default SearchAutocomplete;
