import { useRouter } from 'next/router';

function withAuth<P>(WrappedComponent: React.ComponentType<P>) {
  return (props: P) => {
    if (typeof window !== 'undefined') {
      const router = useRouter();

      const accessToken = localStorage.getItem('@coingoback:token');

      if (!accessToken) {
        const pathname = router.pathname;

        window.location.href = `/login?from=${encodeURIComponent(pathname)}`;

        return <></>;
      }

      return <WrappedComponent {...props} />;
    }

    return <></>;
  };
}

export default withAuth;
