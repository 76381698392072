import styled, { css } from 'styled-components';

interface ContainerProps {
  isLastCard?: boolean;
}

export const Container = styled.div<ContainerProps>`
  flex-shrink: 0;
  height: 230px;
  width: 230px;
  border-radius: 22px;
  border: 1px solid #000;
  box-shadow: 4px 4px #000;
  background: #fff;
  padding: 20px;
  margin-right: 5px;
  margin-top: 33px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  transition: filter 0.2s;

  @media (max-width: 443px) {
    margin-top: 23px;
    height: 150px;
    width: 150px;
  }

  @media (max-width: 351px) {
    height: 125px;
    width: 125px;
  }

  &:hover {
    filter: ${props => (!props.isLastCard ? '' : 'none')};
  }

  ${props =>
    !props.isLastCard &&
    css`
      cursor: pointer;
    `}

  .loading {
    color: #000;
  }
`;

export const Title = styled.div`
  width: 100%;

  .row-skeleton {
    height: 14px;
    width: 80%;
    margin: 0 auto;
  }
`;

export const Logo = styled.div`
  width: 100%;

  .square-skeleton {
    height: 60px;
    width: 80%;
    margin: 0 auto;

    @media (max-width: 443px) {
      height: 50px;
    }

    @media (max-width: 351px) {
      height: 40px;
    }
  }
`;

export const CashbackInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CurrentCashback = styled.div`
  .row-skeleton {
    height: 28px;
    width: 74px;

    @media (max-width: 443px) {
      height: 24px;
    }

    @media (max-width: 351px) {
      height: 20px;
    }
  }
`;

export const Description = styled.div`
  .row-skeleton {
    margin-top: 4px;
    height: 14px;
    width: 64px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const CategoryTitle = styled.div`
  width: 100%;

  .row-skeleton {
    height: 18px;
    width: 80%;
    margin: 0 auto;
  }
`;

export const AlignCategoryIcon = styled.div`
  width: 100%;

  .square-skeleton {
    height: 60px;
    width: 80%;
    margin: 0 auto;
  }
`;

export const CategoryItemContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const CategoryItemCircle = styled.div`
  height: 16px;
  width: 16px;

  background: ${props => props.theme.colors.secondary};
  border-radius: 50%;
  margin: 0 12px;
`;

export const CategoryItemTitle = styled.div`
  width: 80%;

  .row-skeleton {
    height: 18px;
    width: 80%;
  }
`;
