import React, { useState } from 'react';
import { ChevronRight } from 'react-feather';
import { useAuth } from '../../hooks/auth';

import { Collapse } from '@mui/material';
import { t } from 'i18next';
import router from 'next/router';
import DeleteAcont from '../ProfileModal/ConfigModal/index';
import Guest from './AnotherModal/guest/index';
import Invite from './AnotherModal/invite/index';
import {
  Container,
  Item,
  Items,
  ItemText
} from './styles';

interface Props {
  onClose: () => void;
}

const ProfileModal: React.FC<Props> = ({ onClose }) => {
  const { logout, user } = useAuth();
  const [openMenuConfig, setOpenMenuConfig] = useState<boolean>(null);
  const [openMenuAnother, setOpenMenuAnother] = useState<boolean>(null);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalInvite, setOpenModalInvite] = useState(false);
  const [openModalGuest, setOpenModalGuest] = useState(false);

  const handleLogout = async () => {
    onClose();
    await logout();
    // Router.push('/'); // We've disable this to allow Android webview listening for route change on logout
    window.location.href = '/';
  };

  const toggleMenuConfig = () => {
    if (openMenuConfig === null || openMenuConfig === false) {
      setOpenMenuConfig(true);
    } else {
      setOpenMenuConfig(false);
    }
  };

  const toggleMenuAnother = () => {
    if (openMenuAnother === null || openMenuAnother === false) {
      setOpenMenuAnother(true);
    } else {
      setOpenMenuAnother(false);
    }
  };

  const handleOpenModalDelete = () => {
    setOpenModalDelete(true);
  };

  const handleCloseModalDelete = () => {
    setOpenModalDelete(false);
  };

  const handleOpenModalInvite = () => {
    setOpenModalInvite(true);
  };

  const handleCloseModalAnother = () => {
    setOpenModalInvite(false);
  };

  const handleOpenModalGuest = () => {
    setOpenModalGuest(true);
  };

  const handleCloseModalGuest = () => {
    setOpenModalGuest(false);
  };

  const verifyDate = (data: Date) => {
    const currentDate = new Date();
    const diff = currentDate.getTime() - data.getTime();
    const diffDays = Math.floor(diff / (1000 * 60 * 60 * 24));

    return diffDays < 3;
  };

  return (
    <>
      <DeleteAcont open={openModalDelete} onClose={handleCloseModalDelete} />
      {openModalInvite && (
        <Invite open={openModalInvite} onClose={handleCloseModalAnother} />
      )}
      {openModalGuest && (
        <Guest open={openModalGuest} onClose={handleCloseModalGuest} />
      )}
      <Container>
        {/* <AlignUserRank>
        <UserRank>Noob</UserRank>
        <UserLevel>Nível 0</UserLevel>
      </AlignUserRank>
      <Hint>Seu nível dentro do Cointimes</Hint>
      <HintTitle>Como faço para subir de nível</HintTitle>
      <Hint>Para subir de nível, invista R$ 1</Hint>
      <HintTitle>Você investiu: R$ 0,00</HintTitle> */}
        <Items>
          {/* <Item>
          <ItemText>Como funciona?</ItemText>
          <ChevronRight />
        </Item>
        <Divider />
        <Item>
          <ItemText>Histórico de Clicks</ItemText>
          <ChevronRight />
        </Item>
        <Item>
          <ItemText>Configurações</ItemText>
          <ChevronRight />
        </Item> */}

          <Item>
            <button
              className="item"
              onClick={() => {
                router.push('/cashback');
              }}
            >
              <div>{t('components.profile-modal.wallet')} <ChevronRight /></div>
            </button>
          </Item>

          <Item>
            <button
              className="item"
              onClick={() => {
                router.push('/external-wallets');
              }}
            >
              <div>{t('components.profile-modal.my-airdrop-wallets')} <ChevronRight />
              </div>
            </button>
          </Item>

          <Item>
            <button className="item" onClick={toggleMenuConfig}>
              <div>{t('components.profile-modal.settings')} <ChevronRight /></div>
            </button>
          </Item>

          <Collapse in={openMenuConfig}>
            <Item>
              <button className="item" onClick={handleOpenModalDelete}>
                <div className="insideCollapse">
                  <p>{t('components.profile-modal.delete-my-account')}</p>
                </div>
              </button>
            </Item>
          </Collapse>

          <Item>
            <button className="item" onClick={() => {
              router.push('/recommendation');
            }}>
              <div>{t('components.profile-modal.refer-and-earn')}</div>
            </button>
          </Item>

          <button className="item" onClick={handleLogout}>
            <div>
              <Item>
                <ItemText>{t('components.profile-modal.logout')}</ItemText>
                <ChevronRight />
              </Item>
            </div>
          </button>
        </Items>
      </Container>
    </>
  );
};

export default ProfileModal;
