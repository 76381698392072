import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;

  height: 72px;
  width: 100%;
  margin-top: 20px;
  padding: 8px;

  border: 1px solid black;
  border-radius: 22px;
  box-shadow: 4px 4px #fe501c;

  div {
    margin-left: 8px;
    display: flex;
    flex-direction: column;

    h1 {
      font-size: 12px;
      margin-bottom: 4px;
    }
    span {
      font-size: 10px;
      padding: 0 25px 0 0;
    }
  }
`;
