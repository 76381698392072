export function isOnPopQuest(event): boolean {
  if (event.detail === null) {
    return false;
  }

  if (event.detail.inAppUrl && event.detail.dataFromPage && event.detail.inAppUrl.startsWith('/coingoback-quest-navigation/quests/')) {
    const dataFromPage = event.detail.dataFromPage;

    if (dataFromPage.quest) {
      return true;
    }
  }

  return false;
}
