import React from 'react';
import DefaultLayout from '../Default';
import MainLayout from '../Main';
import LoginLayout from '../Login';
import EmptyLayout from '../Empty';

const layouts = {
  default: DefaultLayout,
  main: MainLayout,
  login: LoginLayout,
  empty: EmptyLayout,
};

interface Props {
  children: React.ReactNode & { type: { layout: string } };
}

const LayoutWrapper: React.FC = (props: Props) => {
  const Layout = layouts[props.children.type.layout];
  if (Layout != null) {
    return <Layout {...props}>{props.children}</Layout>;
  }
  return <DefaultLayout {...props}>{props.children}</DefaultLayout>;
};

export default LayoutWrapper;
