import React, { useState } from 'react';
import { Button } from '../../UI';
import { Container } from './styles';
import { useCashout } from '../../../hooks/cashout';
import { toastError, toastSuccess } from '../../Toast';
import { CircularProgress } from '@mui/material';
import Modal from '../../Modal';
import { useAuth } from '../../../hooks/auth';

interface Props {
  open: boolean;
  onClose: (done?: boolean) => void;
}

const DeleteAcont: React.FC<Props> = ({ open, onClose }) => {
  const [deleted, setDeleted] = useState(false);
  const { deleteUser } = useAuth();

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Container>
          <h1>Excluir conta</h1>
          <p>
            Você tem certeza que deseja excluir sua conta? <br />
            Essa ação não poderá ser desfeita.
          </p>
          <div>
            <Button
              onClick={() => {
                onClose();
              }}
            >
              Voltar
            </Button>
            <Button
              onClick={async () => {
                await deleteUser();
                window.location.replace('/');
                setDeleted(true);
              }}
            >
              {deleted ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                'Excluir'
              )}
            </Button>
          </div>
        </Container>
      </Modal>
    </>
  );
};
export default DeleteAcont;
