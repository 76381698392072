import styled from 'styled-components';

interface ButtonLargeProps {
  active?: boolean;
}

export const ButtonLarge = styled.button<ButtonLargeProps>`
  color: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
  div{
    margin-top: -5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .btcIcon{
      background: #FE501C;
      width: 39px;
      height: 39px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      border: #BF421D 2px solid;
      box-shadow: 1px 5px 21px 4px rgba(255, 140, 105, 0.45);
    }
  }
`;
