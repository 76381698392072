import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
`;

export const PageContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  @media (min-width: 1200px) {
    min-width: 1200px
  }

  max-width: 1200px;
  margin: 0 auto;
`;

export const ScrollContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  min-height: calc(100vh - 64px);
  transition: all 200ms ease-in;

  @media (min-width: 770px) {
    margin-top: 132px;
  }
`;
