import styled from 'styled-components';

export const Title = styled.h1`
  text-align: center;
  font-size: 35px;
  font-family: ${props => props.theme.font.default};
  font-weight: bold;
  color: #000;

  @media (max-width: 443px) {
    font-size: 22px;
  }
`;
