import { gql } from '@apollo/client';
import React, { createContext, useContext } from 'react';

import {
  BalanceCashback,
  CreateExchangeRequestInput,
  Order,
} from '../models/cashback';
import { initializeApollo } from '../services/apolloClient';

const apolloClient = initializeApollo();

interface CashbackContextData {
  getOrders(page: number): Promise<Order[]>;
  getBalanceCashback(): Promise<BalanceCashback>;
  getCurrencyEnum(): Promise<string[]>;
  createExchangeRequest(input: CreateExchangeRequestInput): Promise<string>;
  linkAdsDeviceToAccount(device_id: string): Promise<string>;
}

const CashbackContext = createContext<CashbackContextData>(
  {} as CashbackContextData
);

export const CashbackProvider: React.FC = ({ children }) => {
  const getOrders = async (page: number): Promise<Order[]> => {
    return new Promise<Order[]>((resolve, reject) => {
      apolloClient
        .query({
          query: gql`
            query {
              orders(limit: 20, offset: ${page * 20}) {
                id
                advertiser {
                  name
                }
                status
                created_at_formatted
                progress
                cashback_amount_formatted
              }
            }
          `,
        })
        .then(async response => {
          resolve(response?.data?.orders);
        })
        .catch(error => {
          reject(error.response?.data ? error.response.data : error.message);
        });
    });
  };

  const getBalanceCashback = async (): Promise<BalanceCashback> => {
    return new Promise<BalanceCashback>((resolve, reject) => {
      apolloClient
        .query({
          query: gql`
            query {
              userBalanceCashBack {
                total_available_to_invest_formatted
                total_available_to_invest
                total_available_to_invest_in_btc_formatted
                total_invested_in_btc_formatted
                total_balance_pending_cashback_formatted
                total_locked {
                  currency
                  balance
                  balance_formatted
                }
              }
            }
          `,
        })
        .then(async response => {
          resolve(response?.data?.userBalanceCashBack);
        })
        .catch(error => {
          reject(error.response?.data ? error.response.data : error.message);
        });
    });
  };

  const getCurrencyEnum = async (): Promise<string[]> => {
    return new Promise<string[]>((resolve, reject) => {
      apolloClient
        .query({
          query: gql`
            query {
              listExchangeRequestCurrencyToEnum
            }
          `,
        })
        .then(async response => {
          resolve(response?.data?.listExchangeRequestCurrencyToEnum);
        })
        .catch(error => {
          reject(error.response?.data ? error.response.data : error.message);
        });
    });
  };

  const createExchangeRequest = async (
    inputValue: CreateExchangeRequestInput
  ) => {
    return new Promise<string>((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`
            mutation createExchangeRequest(
              $input: CreateExchangeRequestInput!
            ) {
              createExchangeRequest(input: $input) {
                id
              }
            }
          `,
          variables: {
            input: inputValue,
          },
        })
        .then(async response => {
          resolve(response?.data?.createExchangeRequest?.id);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  };

  const linkAdsDeviceToAccount = async (
    device_id: string
  ) => {
    return new Promise<string>((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: gql`
            mutation linkAdsDeviceToAccount($device_id: String!){
              linkAdsDeviceToAccount(device_id: $device_id){
                message
              }
            }
          `,
          variables: {
            device_id: device_id,
          },
        })
        .then(async response => {
          resolve(response?.data?.linkAdsDeviceToAccount?.message);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  };


  return (
    <CashbackContext.Provider
      value={{
        getOrders,
        getBalanceCashback,
        getCurrencyEnum,
        createExchangeRequest,
        linkAdsDeviceToAccount
      }}
    >
      {children}
    </CashbackContext.Provider>
  );
};

function useCashback(): CashbackContextData {
  const context = useContext(CashbackContext);

  if (!context) {
    throw new Error(' useCashback must be used within an CashbackProvider ');
  }
  return context;
}

export { CashbackContext, useCashback };
