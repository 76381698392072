import React from 'react';
import QuestionCircle from '../../../assets/question-circle-icon.svg';

import { Container } from './styles';

interface QuestionItemProps {
  label: string;
}

const QuestionItem: React.FC<QuestionItemProps> = ({ label }) => {
  return (
    <Container>
      <QuestionCircle style={{ minWidth: 32 }}/>
      <span>{label}</span>
    </Container>
  );
};

export default QuestionItem;
