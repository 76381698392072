import styled, { css } from 'styled-components';

export const CardsContainer = styled.div`
  display: flex;
`;

interface CardContentProps {
  selected?: boolean;
}

interface LabelProps {
  disableBold?: boolean;
}

interface InputComponentProps {
  maxButton?: boolean;
}

export const CardContent = styled.div<CardContentProps>`
  cursor: pointer;
  height: 100px;
  width: 100px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border: 1px solid #000;
  border-radius: 8px;
  box-shadow: 4px 4px #000;

  margin-right: 24px;

  transition: background-color 0.2s;

  &:hover {
    background-color: #f5f5f5;
  }

  ${props =>
    props.selected &&
    css`
      box-shadow: ${props => props.theme.colors.primary.main} 4px 4px 0px -1px,
        #000 4px 4px;
    `}
`;

export const CardLabel = styled.span`
  margin-top: 4px;
`;

export const InputContainer = styled.div`
  display: flex;
  box-shadow: 4px 4px #000;
  border: 1px solid #000;
  border-radius: 8px;

  .alignIcon {
    width: 14px;
    display: flex;
    align-items: center;
    margin-left: 14px;
  }

  input {
    height: 32px;
    width: 100%;
    border-radius: 8px;
    margin: 2px 0;
  }
`;

export const InputCustomComponent = styled.input<InputComponentProps>`
  padding: 0 ${props => (props.maxButton ? 0 : 18)}px 0 18px;
`;

export const InputComponent = styled.input<InputComponentProps>`
  display: flex;
  box-shadow: 4px 4px #000;
  border: 1px solid #000;
  padding: 0 ${props => (props.maxButton ? 0 : 18)}px 0 18px;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    box-shadow: 4px 4px #000 !important;
    -webkit-background-clip: text;
  }

`;

export const MaxButton = styled.button`
  height: 22px;
  margin: auto 6px;
  font-weight: bold;
  font-size: 11px;
  background-color: #ffeae3;
  padding: 0 6px;
  color: ${props => props.theme.colors.primary.main};
  border-radius: 8px;
`;

export const Label = styled.span<LabelProps>`
  font-weight: ${props => (props.disableBold ? 'normal' : 'bold')};
  padding: 12px 0;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  margin: 12px 0;
`;

export const DoubleLabelRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const CheckboxContainer = styled.div``;

export const CheckboxContent = styled.div`
  display: flex;
  align-items: center;
`;

export const Circle = styled.div`
  cursor: pointer;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  background-color: #000;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 12px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #000;
`;

export const ErrorMessage = styled.p`
  color: ${props => props.theme.colors.danger.main};
  margin-top: 12px;
`;
