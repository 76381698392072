import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 180px;
  height: 100%;
  padding: 0 20px;
  margin-right: 20px;
  border-right: 1px solid;

  .row {
    display: flex;
    justify-content: flex-end;
  }

  .row-skeleton {
    height: 14px;
    width: 80%;
  }

  .row-skeleton.btc {
    margin-bottom: 4px;
  }

  .row-skeleton.currency {
    width: 60%;
  }
`;
