import React, { useEffect, useState } from 'react';
import {
  Animation,
  BoxSaldo,
  Container,
  DefaultProfileImage,
  FeaturedMenu,
  Header,
  Linha,
  MenuCard,
  ProfileContainer,
  ProfileImage,
  UserName,
  DefaultHeaderContainer,
  ContainerApp,
  ProfileContainerApp,
  DefaultProfileImageApp,
} from './styles';
import { Search, User } from 'react-feather';
import Icon from '../../../assets/logo-short-cointimes.svg';
import { useRouter } from 'next/router';
import { useAuth } from '../../../hooks/auth';
import LoadingUser from '../../Shimmer/LoadingUser';
import LoadingSearchMobile from '../../Shimmer/LoadingSearchMobile';
import ProfileModal from '../../ProfileModal';
import Popover from '@mui/material/Popover';
import { Visibility, VisibilityOff, Cached } from '@mui/icons-material';
import { UserBalance } from '../../../models/user';
import Skeleton from '../../Skeleton';
import { useUser } from '../../../hooks/user';
import Varrow from '../../../assets/V-arrow.png';
import { CircularProgress, Collapse } from '@mui/material';
import { SearchButton, SearchContainer } from '../styles';
import SearchAutocomplete from '../../SearchAutocomplete';
import { useHome } from '../../../hooks/home';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { Option } from '../../../models/searchAutocomplete';
import Link from 'next/link';
import ColapseCards from './cards';
import RewardCard from '../../UI/Card/RewardCard';
import VersionHider from '../../VersionHider';
import IndicationBar from '../../../components/Indication/Bar';

interface DefaultHeaderProps {
  name: string;
}

const HeaderMobile = () => {
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingSearchContainer, setLoadingSearchContainer] = useState(true);
  const [userBalance, setUserBalance] = useState<UserBalance>();
  const [isLoadingUserBalance, setIsLoadingUserBalance] = useState<boolean>(
    true
  );
  const [width, setWidth] = useState(0);

  const { getIsAuthenticated, user } = useAuth();
  const { getUserBalance } = useUser();
  const { getAdvertisersByString } = useHome();

  const router = useRouter();
  const [showElement, setShowElement] = useState(true);
  const refreshBalance = () => {
    try {
      setIsLoadingUserBalance(true);
      getUserBalance().then(userBalanceData => {
        if (userBalanceData) {
          setUserBalance(userBalanceData);
        }
        setIsLoadingUserBalance(false);
      });
    } catch (error) {
      setIsLoadingUserBalance(false);
      console.log(error);
    }
  };

  useEffect(() => {
    const localToken = localStorage.getItem('@coingoback:token');

    async function getUserBalanceData() {
      try {
        const userBalanceData = await getUserBalance();
        if (userBalanceData) {
          setUserBalance(userBalanceData);
        }
        setIsLoadingUserBalance(false);
        setLoadingSearchContainer(false);
      } catch (error) {
        setIsLoadingUserBalance(false);
        setLoadingSearchContainer(false);

        console.log(error);
      }
    }

    if (localToken) {
      getUserBalanceData();
    }
  }, []);

  useEffect(() => {
    setWidth(window.innerWidth);

    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSearch = async (inputValue: string) => {
    try {
      const response = await getAdvertisersByString(inputValue);

      return response.map(advertiser => {
        return { value: advertiser.slug, label: advertiser.name };
      });
    } catch (error) {
      console.log(error);
    }
  };

  const searchDebounced = AwesomeDebouncePromise(handleSearch, 500);

  const handleDebounceSearch = async (inputValue: string) => {
    return await searchDebounced(inputValue);
  };

  const handleSelect = (option: Option) => {
    if (option) {
      setLoadingSearch(true);
      localStorage.setItem('@coingoback:searchLoading', 'true');

      const loadingInterval = setInterval(() => {
        const searchLoading = localStorage.getItem('@coingoback:searchLoading');
        if (!searchLoading) {
          clearInterval(loadingInterval);
          setLoadingSearch(false);
        }
      }, 200);

      router.push(`/store/${option.value}`);
    }
  };

  const HomeHeader: React.FC = () => {
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

    const open = Boolean(anchorEl);
    const router = useRouter();

    const showOrHide = () => setShowElement(!showElement);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <Header>
        <Container>
          <Icon className="icon" />
          {getIsAuthenticated() ? (
            <>
              <ProfileContainer onClick={handleClick}>
                {user !== undefined ? (
                  <>
                    <UserName>{user?.name ?? ''}</UserName>
                    <ProfileImage />
                    <DefaultProfileImage>
                      <User height={24} />
                    </DefaultProfileImage>
                  </>
                ) : (
                  <LoadingUser />
                )}
              </ProfileContainer>
            </>
          ) : (
            <div className="entrarapp">
              <button
                onClick={() => {
                  router.push('/login');
                }}
              >
                Login
              </button>
              <div className="divider"></div>
              <button
                onClick={() => {
                  router.push('/signup');
                }}
              >
                Cadastro
              </button>
            </div>
          )}
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <ProfileModal onClose={handleClose} />
          </Popover>
        </Container>
        <Linha>
          <div />
        </Linha>
        <BoxSaldo>
          {user !== undefined ? (
            <>
              <div className="bitinfo">
                {isLoadingUserBalance ? (
                  <>
                    <div className="loading">
                      <Skeleton className="row" />
                    </div>
                    <button className="rolling" onClick={refreshBalance}>
                      <Cached />
                    </button>
                  </>
                ) : showElement ? (
                  <>
                    <div className="bitcoin">
                      <h1> {userBalance?.total_balance_btc_formatted ?? ''}</h1>
                    </div>
                    <div className="reais">
                      ≅
                      {userBalance?.total_balance_base_currency_formatted ?? ''}
                    </div>
                    <button className="refresh" onClick={refreshBalance}>
                      <Cached />
                    </button>
                  </>
                ) : (
                  <div className="empty" />
                )}
              </div>

              <button onClick={showOrHide}>
                {showElement ? (
                  <Visibility />
                ) : (
                  <div className="visibilityOff">
                    <VisibilityOff />
                  </div>
                )}
              </button>
            </>
          ) : (
            <></>
          )}
        </BoxSaldo>
        <ColapseCards />
        {width < 770 && <IndicationBar />}
        <VersionHider version="1.0.4">
          <div className="rewardCard">
            <RewardCard />
          </div>
        </VersionHider>
        <div className="mobileSearch">
          {getIsAuthenticated() ? (
            <>
              {loadingSearchContainer ? (
                <LoadingSearchMobile />
              ) : (
                <SearchContainer>
                  <SearchButton style={{border: 'none', boxShadow: 'none', backgroundColor: 'transparent'}}>
                    {loadingSearch ? (
                      <CircularProgress size={14} className="loading" />
                    ) : (
                      <Search height={14} />
                    )}
                  </SearchButton>
                  <SearchAutocomplete
                    placeholder="Buscar lojas com cashback"
                    loadOptions={handleDebounceSearch}
                    onChange={handleSelect}
                  />
                </SearchContainer>
              )}
            </>
          ) : (
            <>
              {' '}
              <SearchContainer>
                <SearchButton style={{border: 'none', boxShadow: 'none', backgroundColor: 'transparent'}}>
                  {loadingSearch ? (
                    <CircularProgress size={14} className="loading" />
                  ) : (
                    <Search height={14} />
                  )}
                </SearchButton>
                <SearchAutocomplete
                  placeholder="Buscar lojas com cashback"
                  loadOptions={handleDebounceSearch}
                  onChange={handleSelect}
                />
              </SearchContainer>
            </>
          )}
        </div>
      </Header>
    );
  };

  const HeaderMobileWeb: React.FC = () => {
    const router = useRouter();

    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    return (
      <ContainerApp>
        <div className="header">
          <Link href="/">
            <Icon className="icon" />
          </Link>
          <div className="mobileSearch">
            <SearchContainer variant="filled">
              <SearchButton transparent>
                {loadingSearch ? (
                  <CircularProgress size={14} className="loading" />
                ) : (
                  <Search height={14} />
                )}
              </SearchButton>
              <SearchAutocomplete
                placeholder="Buscar lojas com cashback"
                loadOptions={handleDebounceSearch}
                onChange={handleSelect}
              />
            </SearchContainer>
          </div>
          {getIsAuthenticated() ? (
            <>
              <ProfileContainerApp onClick={handleClick}>
                <DefaultProfileImageApp>
                  <User />
                </DefaultProfileImageApp>
              </ProfileContainerApp>
            </>
          ) : (
            <div className="entrarapp">
              <button
                onClick={() => {
                  router.push('/login');
                }}
              >
                Login
              </button>
              <div className="divider"></div>
              <button
                onClick={() => {
                  router.push('/signup');
                }}
              >
                Cadastro
              </button>
            </div>
          )}
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <ProfileModal onClose={handleClose} />
          </Popover>
        </div>
      </ContainerApp>
    );
  };

  const DefaultHeader: React.FC<DefaultHeaderProps> = ({ name }) => {
    const { back } = useRouter();

    return (
      <DefaultHeaderContainer>
        <button onClick={back}>
          <img src={Varrow.src} alt="voltar" />
        </button>
        <h1>{name}</h1>
        <div />
      </DefaultHeaderContainer>
    );
  };

  const DynamicallyHeader = () => {
    const { pathname } = useRouter();

    if (pathname === '/home') {
      return <HomeHeader />;
    } else if (pathname.includes('/news')) {
      return <DefaultHeader name="Notícias" />;
    } else if (pathname.includes('/store')) {
      return <DefaultHeader name="Ganhe bitcoin" />;
    } else if (pathname === '/cashback') {
      return <DefaultHeader name="Carteira" />;
    } else if (pathname === '/cashout') {
      return <DefaultHeader name="Sacar" />;
    } else if (pathname.includes('/quests')) {
      return <DefaultHeader name="Missões" />;
    } else if (pathname === '/help') {
      return <DefaultHeader name="Ajuda" />;
    }
    // else if (pathname === '/xpz-airdrop') {
    //   return <DefaultHeader name="xpz-airdrop" />;
    // }
    // else if (pathname === '/xpz-tasks') {
    //   return <DefaultHeader name="xpz-tasks" />;
    // }
    else if (pathname === '/market') {
      return <DefaultHeader name="Mercado" />;
    } else if (pathname === '/weare') {
      return <HeaderMobileWeb />;
    } else if (pathname === '/') {
      return <HeaderMobileWeb />;
    } else {
      return <></>;
    }
  };

  return <DynamicallyHeader />;
};

export default HeaderMobile;
