import styled from 'styled-components';
import Select from 'react-select';

interface ContainerProps {
  enableShortLabel?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  padding-left: ${props => (props.enableShortLabel ? 18 : 8)}px;

  height: 38px;
  border: 1px solid #000;
  box-shadow: 4px 4px #000;
  border-radius: 8px;
`;

export const CustomSelect = styled(Select).attrs({
  theme: theme => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary: '#FE501C',
      primary25: '#fe501c1f',
      primary50: '#fe501c1f',
      primary75: '#fe501c1f',
    },
  }),
})`
  & .react-select__control {
    z-index: 999;

    width: 100%;
    border: none;
    box-shadow: none;
  }
`;
