import { logEvent } from "firebase/analytics";
import { analytics } from "./firebase";

const logGAEvent = (eventName: string, eventData: Record<string, unknown> = {}): void => {
  logEvent(analytics, eventName, eventData);
}

type EventName = Lowercase<string>;

class GAEvent<D extends Record<string, unknown>> {
  name: EventName;

  constructor(name: EventName) {
    this.name = name;
  }

  emit(data?: D): void {
    logGAEvent(this.name, data);
  }
}

export {logGAEvent, GAEvent};
