import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  min-height: 100vh;
`;

export const PageContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const ScrollContent = styled.div`
  min-height: 100vh;
  height: 100vh;
  transition: all 200ms ease-in;
`;
