import { gql } from '@apollo/client';
import { createContext, useContext } from 'react';

import {
  AuthorizeWithdrawalInput,
  BalanceWalletAvailable,
  CreateWithdrawalRequestInput,
  Currency,
  WithdrawalRequest,
} from '../models/cashout';
import { initializeApollo } from '../services/apolloClient';

const apolloClient = initializeApollo();

interface CashoutContextData {
  getCurrencies(): Promise<Currency[]>;
  getBalanceWalletAvailable(filter: {
    currency: string;
  }): Promise<BalanceWalletAvailable>;
  createWithdrawalRequest(input: CreateWithdrawalRequestInput): Promise<string>;
  getWithdrawalRequests(page: number): Promise<WithdrawalRequest[]>;
  authorizeWithdrawal(input: AuthorizeWithdrawalInput): Promise<string>;
}

const CashoutContext = createContext<CashoutContextData>(
  {} as CashoutContextData
);

const getCurrencies = async (): Promise<Currency[]> => {
  return new Promise<Currency[]>((resolve, reject) => {
    apolloClient
      .query({
        query: gql`
          query {
            moneyOutCurrencies {
              name
              currency
              networks {
                id
                network
                fee
                fee_formatted
                min_withdrawal_amount
                min_withdrawal_amount_formatted
                address_regex
              }
            }
          }
        `,
      })
      .then(async response => {
        resolve(response?.data?.moneyOutCurrencies);
      })
      .catch(error => {
        reject(error.response?.data ? error.response.data : error.message);
      });
  });
};

const getBalanceWalletAvailable = async (filter: {
  currency: string;
}): Promise<BalanceWalletAvailable> => {
  return new Promise<BalanceWalletAvailable>((resolve, reject) => {
    apolloClient
      .query({
        query: gql`
          query BalanceWalletAvailable(
            $filter: BalanceWalletAvailableFilterInput!
          ) {
            balanceWalletAvailable(filter: $filter) {
              currency
              total_available
              total_available_formatted
              wallets {
                wallet
                balance_formatted
                name
              }
            }
          }
        `,
        variables: {
          filter,
        },
      })
      .then(async response => {
        resolve(response?.data?.balanceWalletAvailable);
      })
      .catch(error => {
        reject(error.response?.data ? error.response.data : error.message);
      });
  });
};

const createWithdrawalRequest = async (
  inputValue: CreateWithdrawalRequestInput
) => {
  return new Promise<string>((resolve, reject) => {
    apolloClient
      .mutate({
        mutation: gql`
          mutation($input: CreateWithdrawalRequestInput!) {
            createWithdrawalRequest(input: $input) {
              withdrawal_authorization_request_id
            }
          }
        `,
        variables: {
          input: inputValue,
        },
      })
      .then(async response => {
        resolve(
          response?.data?.createWithdrawalRequest
            ?.withdrawal_authorization_request_id
        );
      })
      .catch(error => {
        reject(error.response?.data ? error.response.data : error.message);
      });
  });
};

const authorizeWithdrawal = async (inputValue: AuthorizeWithdrawalInput) => {
  return new Promise<string>((resolve, reject) => {
    apolloClient
      .mutate({
        mutation: gql`
          mutation($input: AuthorizeWithdrawalInput!) {
            authorizeWithdrawal(input: $input) {
              message
            }
          }
        `,
        variables: {
          input: inputValue,
        },
      })
      .then(async response => {
        resolve(response?.data?.authorizeWithdrawal?.message);
      })
      .catch(error => {
        reject(error.response?.data ? error.response.data : error.message);
      });
  });
};

const getWithdrawalRequests = async (
  page: number
): Promise<WithdrawalRequest[]> => {
  return new Promise<WithdrawalRequest[]>((resolve, reject) => {
    apolloClient
      .query({
        query: gql`
          query {
            withdrawalRequests(limit: 5, offset: ${page * 5}) {
              id
              currency
              amount
              amount_formatted
              network {
                network
              }
              wallets {
                wallet_formatted
                amount
                amount_formatted
              }
              type
              content {
                pix {
                  key_type
                  key_value
                }
                cryptocurrency {
                  wallet_address
                }
              }
              withdrawal_confirmation {
                tx_id
              }
              status_formatted
              created_at
              created_at_formatted
            }
          }
        `,
      })
      .then(async response => {
        resolve(response?.data?.withdrawalRequests);
      })
      .catch(error => {
        reject(error.response?.data ? error.response.data : error.message);
      });
  });
};

export const CashoutProvider: React.FC = ({ children }) => {
  return (
    <CashoutContext.Provider
      value={{
        getCurrencies,
        getBalanceWalletAvailable,
        createWithdrawalRequest,
        getWithdrawalRequests,
        authorizeWithdrawal,
      }}
    >
      {children}
    </CashoutContext.Provider>
  );
};

function useCashout(): CashoutContextData {
  const context = useContext(CashoutContext);

  if (!context) {
    throw new Error(' useCashout must be used within an CashoutProvider ');
  }
  return context;
}

export { CashoutContext, useCashout };
