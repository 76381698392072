import styled from 'styled-components';

export const Container = styled.div`
  @media (min-width: 770px) {
    display: none;
  }
  position: fixed;
  bottom: 0;
  z-index: 1000;

  height: 80px;
  width: 100%;
  border-radius: 20px 20px 0 0;

  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: #000;
`;

export const NewContainer = styled.div`
  @media (min-width: 770px) {
    display: none;
  }

  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 20px;

  &.show {
    transition: 500ms;
    bottom: 0;
  }

  &.hide {
    transition: 500ms;
    transform: translateY(150px);
  }
`;
