import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  .row {
    width: 100%;
    display: flex;
  }

  .row-skeleton {
    height: 14px;
    width: 100%;
  }
`;
