import React from 'react';
import * as Card from './styles';
import Link from 'next/link';

type ActionType = {
  href?: string;
};

const Action: React.FC<ActionType> = props => {
  return (
    <Card.Text>
      <Link href={props.href}>{props.children}</Link>
    </Card.Text>
  );
};

export default { Action, ...Card };
