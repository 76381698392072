import styled from 'styled-components';

export const Container = styled.div`
  text-align: -webkit-center;
  h1 {
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 20px;
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    
    @media (max-width: 390px) {
      flex-direction: column;
    }
  }
  span {
    font-weight: 500;
    overflow-wrap: anywhere;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .row {
    width: 200px;
    height: 39px;
    margin-bottom: 10px px;
  }
  .rowButton {
    width: 150px;
    height: 44px;
    margin-bottom: 15px;
  }
  .share-link{
    background: #e0e0e0;
    padding: 10px;
    border-radius: 15px;
  }
`;

export const Content = styled.div`
  .popup {
    position: fixed;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    color: #000;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    z-index: 999;
    animation: fadeOut 2s ease-in-out forwards;
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

export const WppButton = styled.button`
  display: flex;
  align-items: center;

  background-color: #1ee55a;
  color: #fff;
  font-weight: bold;

  border-radius: 4px;

  padding: 10px;

  cursor: pointer;
  transition: 0.3s;

  svg {
    margin-right: 10px;
  }
`;

export const ShereSocial = styled.div`
  display: flex;
  justify-content: center;

  div {
    width: 150px;
    display: flex;
    justify-content: space-between;
  }

  .rowSocial {
    width: 150px;
    height: 27px;
  }
`;
