import React from 'react';
import Skeleton from '../../Skeleton';

import * as StoreCard from './styles';

const Default: React.FC = () => {
  return (
    <StoreCard.Container>
      <StoreCard.Logo>
        <Skeleton className="square-skeleton" />
      </StoreCard.Logo>
      <StoreCard.CashbackInfo>
        <StoreCard.CurrentCashback>
          <Skeleton className="row-skeleton" />
        </StoreCard.CurrentCashback>
      </StoreCard.CashbackInfo>
    </StoreCard.Container>
  );
};

const CategoryCard: React.FC = () => {
  return (
    <StoreCard.Container>
      <StoreCard.CategoryTitle>
        <Skeleton className="row-skeleton" />
      </StoreCard.CategoryTitle>
      <StoreCard.AlignCategoryIcon>
        <Skeleton className="square-skeleton" />
      </StoreCard.AlignCategoryIcon>
    </StoreCard.Container>
  );
};

const CategoryItem: React.FC = () => {
  return (
    <StoreCard.CategoryItemContainer>
      <StoreCard.CategoryItemCircle />
      <StoreCard.CategoryItemTitle>
        <Skeleton className="row-skeleton" />
      </StoreCard.CategoryItemTitle>
    </StoreCard.CategoryItemContainer>
  );
};

export default {
  Default,
  CategoryCard,
  CategoryItem,
  ...StoreCard,
};
