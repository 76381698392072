import axios from 'axios';

const axiosClient = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL_AUTH,
  headers: {
    'Content-Type': 'application/json',
    'x-brand': 'COINGOBACK'
  },
});

export default axiosClient;
