import { useEffect } from 'react';
import { AppProps } from 'next/app';
import { ThemeProvider } from 'styled-components';
import { config } from '@fortawesome/fontawesome-svg-core';
import { ApolloProvider } from '@apollo/client';
import { GoogleOAuthProvider } from '@react-oauth/google';

import GlobalStyle from '../styles/global';
import theme from '../styles/theme';

import AppProvider from '../hooks';

import '../styles/css/carousel.min.css';
import '@fortawesome/fontawesome-svg-core/styles.css';
import 'react-toastify/dist/ReactToastify.css';

import LayoutWrapper from '../layouts/LayoutWrapper';
import { useApollo } from '../services/apolloClient';
import { ToastContainer } from 'react-toastify';
import Head from 'next/head';
import { I18nextProvider } from 'react-i18next';
import i18n from '../i18n';
import '../instrument';

config.autoAddCss = false;

const CGBApp: React.FC<AppProps> = ({ Component, pageProps }) => {
  const apolloClient = useApollo(pageProps);

  useEffect(() => {
    const localCountry = localStorage.getItem('@coingoback:localCountry');

    if(localCountry == 'BR') {
      i18n.changeLanguage('pt-BR');
    }

    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles && jssStyles.parentNode)
      jssStyles.parentNode.removeChild(jssStyles);
  }, []);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=no"
        ></meta>
        {/* Google Analytics */}
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
                page_path: window.location.pathname,
              });
            `,
          }}
        />
      </Head>
      <I18nextProvider i18n={i18n}>
        <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}>
          <ThemeProvider theme={theme}>
            <ApolloProvider client={apolloClient}>
              <AppProvider>
                <LayoutWrapper {...pageProps}>
                  <Component {...pageProps} />
                </LayoutWrapper>
                <ToastContainer />
              </AppProvider>
            </ApolloProvider>
            <GlobalStyle />
          </ThemeProvider>
        </GoogleOAuthProvider>
      </I18nextProvider>
    </>
  );
};

export default CGBApp;
