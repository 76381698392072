import React, { useEffect, useState } from 'react';

import { useUser } from '../../../../hooks/user';
import Modal from '../../../Modal';
import Skeleton from '../../../Skeleton';
import { Container, Content, ShereSocial, WppButton } from './styles';

import {
  Facebook,
  FilterNone,
  Reddit,
  Telegram,
  Twitter,
  WhatsApp,
} from '@mui/icons-material';
import {
  FacebookShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import { useAuth } from '../../../../hooks/auth';
import withAuth from '../../../HigherOrder/withAuth';
import { toastError } from '../../../Toast';


interface Props {
  open: boolean;
  onClose: (done?: boolean) => void;
}

const CopyButton = ({ text }) => {
  const [showPopup, setShowPopup] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(text).then(() => {
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 2000);
    });
  };

  return (
    <Content>
      {showPopup && <div className="popup">Copiado!</div>}
      <button onClick={handleCopy}>
        <FilterNone />
      </button>
    </Content>
  );
};

const WhatsappButton = ({ referralCode, userName }) => {
  const shareUrl = 'https://cointimes.com.br/app/';
  const title = `${userName} e o Cointimes estão te dando dando XPZ! \nBasta baixar o app do Cointimes e utilize o código *${referralCode}* para ganhar a criptomoeda gratuitamente.`;

  return (
    <WhatsappShareButton url={shareUrl} title={title}>
      <WppButton>
        <WhatsApp />
        Compartilhar
      </WppButton>
    </WhatsappShareButton>
  );
};

const TelegramButton = ({ referralCode, userName }) => {
  const shareUrl = 'https://cointimes.com.br/app/';
  const title = `${userName} e o Cointimes estão te dando dando XPZ! \nBasta baixar o app do Cointimes e utilize o código ${referralCode} para ganhar a criptomoeda gratuitamente.`;

  return (
    <TelegramShareButton url={shareUrl} title={title}>
      <Telegram />
    </TelegramShareButton>
  );
};

const TwitterButton = ({ referralCode, userName }) => {
  const shareUrl = 'https://cointimes.com.br/app/';
  const title = `${userName} e o Cointimes estão te dando dando XPZ! \nBasta baixar o app do Cointimes e utilize o código ${referralCode} para ganhar a criptomoeda gratuitamente.`;

  return (
    <TwitterShareButton url={shareUrl} title={title}>
      <Twitter />
    </TwitterShareButton>
  );
};

const FacebookButton = ({ }) => {
  const shareUrl = 'https://cointimes.com.br/app/';
  return (
    <FacebookShareButton url={shareUrl}>
      <Facebook />
    </FacebookShareButton>
  );
};

const RedditButton = ({ referralCode, userName }) => {
  const shareUrl = 'https://cointimes.com.br/app/';
  const title = `${userName} e o Cointimes estão te dando dando XPZ! \nBasta baixar o app do Cointimes e utilize o código ${referralCode} para ganhar a criptomoeda gratuitamente.`;

  return (
    <RedditShareButton url={shareUrl} title={title}>
      <Reddit />
    </RedditShareButton>
  );
};

const Invite: React.FC<Props> = ({ open, onClose }) => {
  const [userCode, setUserCode] = useState<string>();
  const { getOrCreateReferralCode } = useUser();
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getUserCode = async () => {
      try {
        const { referral_code } = await getOrCreateReferralCode();
        setUserCode(referral_code);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        toastError(err);
      }
    };
    getUserCode();
  }, []);

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Container>
          <h1>Convidar um amigo</h1>
          <p>Seu Código de convite é:</p>

          {isLoading ? (
            <Skeleton className="row" />
          ) : (
            <div>
              <span>{userCode}</span>
              <CopyButton text={userCode} />
            </div>
          )}
          <p>Compartilhe com seus amigos e ganhe $XPZ!</p>
          {isLoading ? (
            <Skeleton className="rowButton" />
          ) : (
            <>
              {userCode && (
                <WhatsappButton referralCode={userCode} userName={user?.name} />
              )}
            </>
          )}
        </Container>
        <ShereSocial>
          {isLoading ? (
            <Skeleton className="rowSocial" />
          ) : (
            <div>
              {userCode && (
                <TelegramButton referralCode={userCode} userName={user?.name} />
              )}
              {userCode && (
                <TwitterButton referralCode={userCode} userName={user?.name} />
              )}
              {userCode && <FacebookButton />}
              {userCode && (
                <RedditButton referralCode={userCode} userName={user?.name} />
              )}
            </div>
          )}
        </ShereSocial>
      </Modal>
    </>
  );
};

export default withAuth(Invite);
