import React, { useEffect, useState } from 'react';
import OldMobileMenu from './old_menu';
import NewMobileMenu from './new_menu';
import { isAppV2, isOldApp } from '../../services/versionManager';

const MobileMenu: React.FC = () => {
  const [newMobileMenu, setNewMobileMenu] = useState(null);

  useEffect(() => {
    setNewMobileMenu(isAppV2() || !isOldApp());
  }, []);

  return (newMobileMenu !== null ? (newMobileMenu === true ? <NewMobileMenu/> : <OldMobileMenu/>) : <></>);
};

export default MobileMenu;
