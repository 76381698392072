const theme = {
  colors: {
    background: '#FFFFFF',

    primary: {
      main: '#FE501C',
      hover: '#fe501c1f',
    },
    secondary: '#707070',

    text: '#2b2b2b',
    placeholder: '#b9b9b9',

    white: '#fff',
    shadow: '-9px 0 20px rgba(89, 102, 122, 0.1)',

    success: {
      main: '#1D9820',
      hover: '#fe501c1f',
    },

    warning: {
      main: '#FFB100',
      hover: '#fe501c1f',
    },

    danger: {
      main: '#ef233c',
      hover: '#fe501c1f',
    }
  },

  font: {
    default: 'Lexend, Helvetica, sans-serif',
  },
};

export default theme;
