import styled from 'styled-components';

export const Container = styled.div`
  text-align: -webkit-center;
  h1 {
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 20px;
  }

  input {
    display: inline-block;
    margin: 0.1rem auto;
    padding: 0px;
    width: 15ch;
    margin-right: -15px;
    caret-color: rgb(254, 80, 28);
    background: repeating-linear-gradient(
        90deg,
        rgb(254, 80, 28) 0px,
        rgb(254, 80, 28) 1ch,
        transparent 0px,
        transparent 1.5ch
      )
      0px 100% / 15ch 2px no-repeat;
    font: 2.2rem monospace;
    letter-spacing: 0.5ch;

    @media (max-width: 425px) {
      font: 1.2rem monospace;
    }
  }

  .Button {
    margin-top: 20px;
  }
`;
