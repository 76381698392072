import React from 'react';

import { Container, Filler } from './styles';

const ProgressBar: React.FC<{ value: number; bgColor?: string }> = props => {
  return (
    <Container>
      <Filler {...props}></Filler>
    </Container>
  );
};

export default ProgressBar;
