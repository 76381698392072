import styled from 'styled-components';

export const Container = styled.div`
  max-height: calc(100vh - 4.2rem);
  .button-modal {
    border: 0;
    background: transparent;
    width: 100%;
  }
`;

export const ScrollContent = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 6.2rem);
  padding: 50px;

  @media (max-width: 500px) {
    padding: 32px;
  }
`;
