import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://86322e5cb73ce9f32edb5fd7face48bf@o4507466194092032.ingest.us.sentry.io/4507466220109824",
  integrations: [Sentry.replayIntegration()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
