import { useRouter } from 'next/router';
import React from 'react';
import { Container } from './styles';
import Button from './Button';

import Home from '../../assets/MobileMenu/home.svg';
import Wallet from '../../assets/MobileMenu/wallet.svg';
import Win from '../../assets/MobileMenu/win.svg';
import News from '../../assets/MobileMenu/news.svg';
import Help from '../../assets/MobileMenu/help.svg';
import BitcoinIcon from '../../assets/MobileMenu/win.svg';
import IconDollar from '../../assets/MobileMenu/icondollar.svg';
import VersionHider from '../VersionHider';
import ButtonLarge from './ButtonLarge';

const OldMobileMenu: React.FC = () => {
  const { pathname: activePage, push } = useRouter();

  const handleChangePage = (page: string) => {
    push(page);
  };

  return (
    <Container>

      <Button
        onClick={() => handleChangePage('/home')}
        active={activePage === '/home'}
      >
        <Home />
        Inicio
      </Button>
      <Button
        onClick={() => window.location.href = '/news'}
        active={activePage === '/news'}
      >
        <News />
        Noticias
      </Button>

      <VersionHider version="1.0.4">                 
          <ButtonLarge
             onClick={() => window.location.href = '/rewards/home'}
          >
           <div> 
            <div className='btcIcon'> 
            <BitcoinIcon />
            </div>
            <p> Ganhe </p>
           </div>
                  
                   
          </ButtonLarge>

      </VersionHider>

      <Button
        onClick={() => handleChangePage('/cashback')}
        active={activePage === '/cashback'}
      >
        <Wallet />
        Carteira
      </Button>
      {/* <Button>
        <Stories />
        Stories
      </Button> */}
      <Button
        onClick={() => handleChangePage('/recommendation')}
        active={activePage === '/recommendation'}
      >
       
        {/* <Help /> */}
        <IconDollar />
        Indique
      </Button>
    </Container>
  );
};
export default OldMobileMenu;
