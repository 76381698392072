import styled from 'styled-components';

export const Container = styled.div`
  height: 38px;
  width: 100%;
  .container {
    border-radius: 22px;
    height: 100%;
    width: 100%;
  }
`;
