import React, { useEffect, useState } from 'react';
import Link from 'next/link';

import { AdvertiserListItem } from '../../../models/home';
import { Button } from '../../UI';

import LoadingStoreCard from '../../Shimmer/LoadingStoreCard';

import * as StoreCard from './styles';
import { AdvertiserShort } from '../../../models/advertiser';
import LoadingLargeCard from '../../Shimmer/LoadingLargeCard';
import { CircularProgress } from '@mui/material';

interface DefaultStoreCardProps extends LoadingProps {
  store?: AdvertiserListItem;
  title?: string;
  loading?: boolean;
}

interface CategoryStoreCardProps extends LoadingProps {
  category?: {
    name: string;
    slug: string;
    logo?: string;
  };
}

interface CategoryItemProps extends LoadingProps {
  title?: string;
}

interface LargeStoreCardProps extends LoadingProps {
  advetiser?: AdvertiserShort;
  onShowRules?: () => void;
}

const Default: React.FC<DefaultStoreCardProps> = ({
  store,
  isLoading,
  loading,
  title,
}) => {
  if (isLoading) {
    return <LoadingStoreCard.Default />;
  }

  const undfinedImage = 'https://i.imgur.com/SfQ0P2w.jpg';

  const { category_name, image_url, commission_description } = store;

  return (
    <StoreCard.Container title={title}>
      <StoreCard.Title>{category_name}</StoreCard.Title>
      <StoreCard.Logo
        src={image_url}
        onError={(event: any) => {
          event.target.onerror = null;
          event.target.src = undfinedImage;
        }}
      />
      <StoreCard.CashbackInfo>
        <StoreCard.CurrentCashback>
          {commission_description}
        </StoreCard.CurrentCashback>
        <StoreCard.Description>Cashback</StoreCard.Description>
      </StoreCard.CashbackInfo>
      {loading ? (
        <CircularProgress size={20} className="loading" />
      ) : (
        <div style={{ height: 20 }}></div>
      )}
    </StoreCard.Container>
  );
};

const Large: React.FC<LargeStoreCardProps> = ({
  advetiser,
  isLoading,
  onShowRules,
}) => {
  if (isLoading) {
    return <LoadingLargeCard />;
  }

  const [isMobile, setIsMobile] = useState(false);
  const undfinedImage = 'https://i.imgur.com/SfQ0P2w.jpg';

  useEffect(() => {
    const isMobile = localStorage.getItem('@coingoback:isMobile') === 'true';
    setIsMobile(isMobile);
  }, []);

  return (
    <StoreCard.LargeContainer>
      <div className="alignImg" title={advetiser?.name}>
        <StoreCard.Logo
          src={advetiser?.image_url}
          onError={(event: any) => {
            event.target.onerror = null;
            event.target.src = undfinedImage;
          }}
        />
        <StoreCard.Divider />
      </div>
      <div className="alignCashback">
        <div className="cashbackContent">
          <StoreCard.CurrentCashback>
            Ganhe {advetiser?.commission_description}
          </StoreCard.CurrentCashback>
          <div className="alignButtons">
            <Link
              href={`/tracker-advertiser/${advetiser?.id}?source=${
                isMobile ? 'mobile' : 'site'
              }&domain=${advetiser?.domain}`}
              passHref
            >
              {isMobile ? (
                <Button> Ativar Cashback</Button>
              ) : (
                <a target="_blank">
                  <Button> Ativar Cashback</Button>
                </a>
              )}
            </Link>
            <Button className="button modal" onClick={onShowRules} secondary>
              Regras e Exceções
            </Button>
          </div>
        </div>
        <StoreCard.Divider />
      </div>
      <StoreCard.Empty className="empty" />
    </StoreCard.LargeContainer>
  );
};

const LastCard: React.FC = () => {
  return (
    <StoreCard.Container isLastCard>
      <StoreCard.Content>
        <StoreCard.LastCardTitle>Mais de 400</StoreCard.LastCardTitle>
        <StoreCard.SubTitle>
          Lojas e serviços com seu dinheiro virando investimento
        </StoreCard.SubTitle>
      </StoreCard.Content>
      <Link href="stores">
        <Button>Ver todas as lojas</Button>
      </Link>
    </StoreCard.Container>
  );
};

const CategoryCard: React.FC<CategoryStoreCardProps> = ({
  category,
  isLoading,
}) => {
  if (isLoading) {
    return <LoadingStoreCard.CategoryCard />;
  }

  const { name, logo } = category;

  return (
    <StoreCard.Container>
      <StoreCard.CategoryTitle>{name}</StoreCard.CategoryTitle>
      <StoreCard.AlignCategoryIcon>
        <img src={logo} height={62} width={62} />
      </StoreCard.AlignCategoryIcon>
    </StoreCard.Container>
  );
};

const CategoryItem: React.FC<CategoryItemProps> = ({ title, isLoading }) => {
  if (isLoading) {
    return <LoadingStoreCard.CategoryItem />;
  }

  return (
    <StoreCard.CategoryItemContainer>
      <StoreCard.CategoryItemCircle />
      <StoreCard.CategoryItemTitle>{title}</StoreCard.CategoryItemTitle>
    </StoreCard.CategoryItemContainer>
  );
};

export default {
  Default,
  Large,
  LastCard,
  CategoryCard,
  CategoryItem,
  ...StoreCard,
};
