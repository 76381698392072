import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 0;

  span {
    font-size: 17px;
    color: #525252;
    margin-left: 14px;

    @media (max-width: 850px) {
      font-size: 15px;
    }
  }
`;
