export function formatGraphQlInput(input: any){
  return input
  ? `{ ${Object.entries(input)
      .map(([key, value]: any) => {
          if (value !== "") {
              return `${key} : "${value}"`
          }
          return
      })
      .join(", ")} }`
  : undefined;
}
