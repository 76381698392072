import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #000;
  border-radius: 0 0 22px 22px;

  div.arrow {
    display: flex;
    justify-content: center;
    background: #000;
    border-radius: 0 0 22px 22px;
    button {
      width: 100%;
    }
  }

  div.arrow.hide {
    button {
      transform: rotate(0deg);
    }
  }

  div.arrow.show {
    button {
      transform: rotate(180deg);
      width: 100%;
    }
  }
`;

export const TheCards = styled.div`
  display: flex;
  padding: 0 30px;
  justify-content: space-between;

  @media (max-width: 350px) {
    padding: 0 20px;
  }

  @media (max-width: 340px) {
    padding: 0 10px;
  }

  @media (max-width: 320px) {
    padding: 0 5px;
  }

  @media (max-width: 300px) {
    padding: 0px;
  }
  button {
    padding-right: 5px;
  }
`;

export const MenuCard = styled.div`
  background: #fff;
  border-radius: 22px;
  display: flex;
  flex-shrink: 1;
  flex-direction: column;
  justify-content: space-evenly;

  width: 180px;
  height: 180px;
  padding: 0;
  margin: 10px 0;
  box-shadow: #000 4px 4px 0px -1px, #fff 4px 4px;

  align-items: center;
  text-align: center;
  font-weight: bold;

  &:active {
    box-shadow: none;
    transform: translate3d(4px, 4px, 0px);
  }

  span {
    color: #000;
  }

  @media (max-width: 650px) {
    height: 155px;
    width: 155px;
  }

  @media (max-width: 550px) {
    height: 130px;
    width: 130px;
    font-size: 12px;

    svg {
      height: 60px;
    }
  }

  @media (max-width: 480px) {
    height: 105px;
    width: 105px;
    border-radius: 16px;
    
    svg {
      height: 50px;
    }
  }

  @media (max-width: 400px) {
    height: 95px;
    width: 95px;
    font-size: 11px;
   
    svg {
      height: 40px;
    }
  }
`;
