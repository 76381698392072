import { gql } from '@apollo/client';
import React, { createContext, useContext } from 'react';
import { CategoryNews, News, SingleNews } from '../models/news';
import { initializeApollo } from '../services/apolloClient';

const apolloClient = initializeApollo();

interface NewsContextData {
  getNews: (page: number, limit?: number, search?: string) => Promise<News[]>;
  getNewsCategory: (
    page: number,
    limit?: number,
    search?: string
  ) => Promise<CategoryNews[]>;
  getSingleNews: (slug?: string) => Promise<SingleNews>;
}

const NewsContext = createContext<NewsContextData>({} as NewsContextData);

export const NewsProvider: React.FC = ({ children }) => {
  const getNews = async (
    page: number,
    limit = 10,
    search?: string
  ): Promise<News[]> => {
    return new Promise<News[]>((resolve, reject) => {
      apolloClient
        .query({
          query: gql`
            query news($limit: Int, $offset: Int, $search: String) {
              news(limit: $limit, offset: $offset, search: $search) {
                id
                title
                slug
                description
                image
                author
                created_at
                date_formatted
              }
            }
          `,
          variables: {
            limit,
            offset: page * limit,
            search,
          },
        })
        .then(async response => {
          resolve(response?.data?.news);
        })
        .catch(error => {
          reject(error.response?.data ? error.response.data : error.message);
        });
    });
  };

  const getNewsCategory = async (
    page: number,
    limit = 10,
    search?: string
  ): Promise<CategoryNews[]> => {
    return new Promise<CategoryNews[]>((resolve, reject) => {
      apolloClient
        .query({
          query: gql`
            query news($limit: Int, $offset: Int, $search: String) {
              news(
                limit: $limit
                offset: $offset
                search: $search
                tag_id: 2530
              ) {
                id
                title
                content
                slug
                description
                image
                date
                date_formatted
                author
                sort
                created_at
                updated_at
              }
            }
          `,
          variables: {
            limit,
            offset: page * limit,
            search,
          },
        })
        .then(async response => {
          resolve(response?.data?.news);
        })
        .catch(error => {
          reject(error.response?.data ? error.response.data : error.message);
        });
    });
  };

  const getSingleNews = async (slug?: string): Promise<SingleNews> => {
    return new Promise<SingleNews>((resolve, reject) => {
      apolloClient
        .query({
          query: gql`
            query newsBySlug($slug: String!) {
              newsBySlug(slug: $slug) {
                id
                title
                content
                slug
                description
                image
                date
                author
                created_at
                updated_at
              }
            }
          `,
          variables: {
            slug,
          },
        })
        .then(async response => {
          resolve(response?.data?.newsBySlug);
        })
        .catch(error => {
          reject(error.response?.data ? error.response.data : error.message);
        });
    });
  };

  return (
    <NewsContext.Provider value={{ getNews, getNewsCategory, getSingleNews }}>
      {children}
    </NewsContext.Provider>
  );
};

function useNews(): NewsContextData {
  const context = useContext(NewsContext);

  if (!context) {
    throw new Error(' useNews must be used within an NewsProvider ');
  }
  return context;
}

export { NewsContext, useNews };
