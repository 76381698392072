import styled from 'styled-components';

export const Container = styled.div`
  width: 250px;
  padding: 12px 20px;

  button.item {
    color: rgb(43, 43, 43);
    text-align: left;

    div {
      width: 210px;
      display: flex;
      justify-content: space-between;
    }
  }
`;

export const AlignUserRank = styled.div`
  height: 32px;
  display: flex;
  align-items: flex-end;
  margin-bottom: 2px;
`;

export const UserRank = styled.div`
  font-size: 26px;
`;

export const UserLevel = styled.div`
  margin-left: 12px;
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 500;
`;

export const Hint = styled.div`
  font-size: 11px;
  color: #959595;
  margin-bottom: 2px;
`;
export const HintTitle = styled.div`
  font-size: 11px;
  color: #222222;
  margin-top: 6px;
`;

export const Items = styled.div``;

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 6px;
  cursor: pointer;

  .insideCollapse {
    margin-left: 10px;
    font-size: 12px;
    p {
      color: red;
    }
  }
`;

export const ItemText = styled.div`
  font-size: 14px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #222;
  margin: 8px 0;
`;
