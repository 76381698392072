import React, { useEffect, useState } from 'react';
import { compare, CompareOperator, validate } from 'compare-versions';

interface Props {
    version: string
    build?: number
    comparator?: CompareOperator
    children?: React.ReactNode
}

const VersionHider: React.FC<Props> = (props) => {
    const [hiddingElements, setHiddingElements] = useState(true);

    useEffect(() => {
        const mobileVersion = localStorage.getItem('@coingoback:mobileVersion');
        const mobileBuild = localStorage.getItem('@coingoback:mobileBuild');

        let shouldHideElements = true;

        if (mobileVersion !== undefined && validate(mobileVersion)) {
            shouldHideElements = compare(mobileVersion, props.version, props.comparator ?? "<");
        }

        if (mobileBuild !== undefined && props.build !== undefined) {
            shouldHideElements = compare('0.0.' + mobileBuild, '0.0.' + props.build, props.comparator ?? "<");
        }

        if (!shouldHideElements) {
            setHiddingElements(false);
        }
    }, []);

    return (
        <>{!hiddingElements && props.children}</>
    );
}

export default VersionHider;