import styled from 'styled-components';

export const Container = styled.div`
  text-align: -webkit-center;
  h1 {
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 20px;
  }
  button {
    margin-bottom: 20px;
    width: 300px;
    @media (max-width: 768px) {
      width: 90%;
    }
    &:last-child {
      background: red;
    }
  }
`;
