import styled from 'styled-components';

interface ButtonProps {
  disabled?: boolean;
  secondary?: boolean;
  dark?: boolean;
  light?: boolean;
  xpz?: boolean;
  white?: boolean;
  noShadow?: boolean;
  thickBorder?: boolean;
  useButtonColorOnBorder?: boolean;
}

export const Button = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 36px;
  width: 100%;
  border-radius: 8px;
  font-size: 13px;
  font-family: ${props => props.theme.font.default};
  color: #fff;
  transition: box-shadow 0.08s ease-in-out, transform 0.08s ease-in-out;

  ${props => {
    if (props.disabled) {
      return `
          cursor:default;
          background-color: gray;
          box-shadow: #fff 4px 4px 0px -1px, gray 4px 4px;
        `;
    }
    return `
        background-color: ${
          props.dark ? '#000' : props.theme.colors.primary.main
        };
        cursor:pointer;
        :hover{
            opacity: 0.9;
        }
      `;
  }}

  ${props => {
    if (!props.secondary) {
      return `
        box-shadow: #fff 4px 4px 0px -1px, ${
          props.disabled
            ? 'gray'
            : props.dark
            ? '#000'
            : props.theme.colors.primary.main
        } 4px 4px;
        color: #fff;
      `;
    }

    return `
      color: #ababab;
      background-color: transparent;
    `;
  }}

${props => {
    if (props.light) {
      return `
          background-color: #FFF;
          box-shadow: ${props.theme.colors.primary.main} 4px 4px 0px -1px, ${props.theme.colors.primary.main} 4px 4px;
          color: ${props.theme.colors.primary.main};
          border: 1px solid ${props.theme.colors.primary.main};
        `;
    }
  }}

 ${props => {
    if (props.xpz) {
      return `
          background-color: #FFF;
          box-shadow: #9BFF02 4px 4px 0px -1px, #9BFF02 4px 4px;
          color: black;
          border: 1px solid black;
        `;
    }
  }}

  ${props => {
    if (props.disabled) {
      return `
          cursor:default;
          background-color: gray;
          box-shadow: #000 4px 4px 0px -1px, gray 4px 4px;
          transition: none;
        `;
    }
    if (props.white) {
      return `
          background-color: #FFF;
          box-shadow: #FFF 4px 4px 0px -1px, #FFF 4px 4px;
          color: black;
          border: 1px solid black;
        `;
    }
  }}

&:hover,
&:focus {
    opacity: 0.9;
    outline: 0;
  }

  ${props => {
      if (!props.disabled) {
        return `
        &:active {
          box-shadow: none;
          transform: translate3d(4px, 4px, 0px);
        }`
      }
    }
  }
`;

export const FlatButton = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 36px;
  border-radius: 8px;
  font-size: 13px;
  font-family: ${props => props.theme.font.default};
  color: #fff;
  box-shadow: none;
  transition: box-shadow 0.08s ease-in-out, transform 0.08s ease-in-out;

  ${props => {
    if (props.disabled) {
      return `
          cursor: default;
          background-color: gray;
          border: 2px solid gray;
        `;
    }
    return `
        background-color: ${
          props.dark ? '#000' : props.theme.colors.primary.main
        };
        border: 2px solid ${
          props.dark ? '#aaa' : props.theme.colors.primary.main
        };
        cursor:pointer;
        :hover{
            opacity: 0.9;
        }
      `;
  }}

  ${props => {
    if (!props.secondary) {
      return `
        color: #fff;
      `;
    }

    return `
      color: #ababab;
      background-color: transparent;
    `;
  }}

  ${props => {
    if (props.disabled) {
      return `
          cursor:default;
          background-color: gray;
          transition: none;
        `;
    }
    if (props.white) {
      return `
          background-color: #FFF;
          color: black;
        `;
    }
  }}

&:hover,
&:focus {
    opacity: 0.9;
    outline: 0;
  }

  ${props => {
      if (!props.disabled) {
        return `
        &:active {
          box-shadow: none;
          transform: translate3d(4px, 4px, 0px);
        }`
      }
    }
  }

  ${props => {
    if (props.light) {
      return `
          background-color: #FFF;
          color: ${props.theme.colors.primary.main};
          border: 2px solid ${props.theme.colors.primary.main};
        `;
    }
  }}
`;

export const LockField = styled.div`
    width: 100%;
    border-radius: 8px;
    color: #fe501c;
    border: 1px solid #fe501c;
    background-color: #fff;
    box-shadow: #fe501c 4px 4px 0px -1px, #fe501c 4px 4px;
    font-size: 13px;
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: space-between;

    .currencyLocked {
      font-size: 13px;
    }
`
export const LockList = styled.ul`
     text-align: right;
     padding: 10px 15px;
     list-style: none;
     padding: 8px 26px;
     background: #fff;
     margin-top: 11px;
     margin-left: -30px;
     border-radius: 4px;
     font-size: 13px;  
     color: #fe501c;
     box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 0.20), 
                 0px 8px 10px 1px rgb(0 0 0 / 0.14), 
                 0px 3px 14px 2px rgb(0 0 0 / 0.12);
     li{
       margin-bottom: 5px;
     }                             
`