import React, { createContext, useContext, useEffect, useState } from 'react';
import { gql } from '@apollo/client';
import { AxiosResponse } from 'axios';

import { initializeApollo } from '../services/apolloClient';

import {
  ConfirmUser,
  JWT,
  LoginRequest,
  LoginResponse,
  LoginShortRequest,
  SendPhoneValidationSmsResponse,
  SignupRequest,
  SocialLoginRequest,
  User,
  ValidatePhoneResponse,
} from '../models/auth';
import axiosClient from '../services/axiosClient';
import i18n from '../i18n';

const apolloClient = initializeApollo();

interface AuthContextData {
  data: {
    name?: string;
    password?: string;
    user_email?: string;
    code?: string;
  };
  getIsAuthenticated(): boolean;
  login(loginRequest: LoginShortRequest): Promise<LoginResponse>;
  logout(): void;
  signup(signupRequest: SignupRequest): Promise<LoginResponse>;
  socialLogin(socialLoginRequest: SocialLoginRequest): Promise<LoginResponse>;
  user: User;
  deleteUser(): Promise<void>;
  refreshToken(refreshToken: string): Promise<LoginResponse>;
  updateAuth(): void;
  confirmEmail(input: ConfirmUser): Promise<void>;
  setConfirmEmail: (values: ConfirmUser) => void;
  localCountry: string;
  setCountry(country: string): Promise<void>;
  validatePhone(code: string): Promise<ValidatePhoneResponse>;
  sendPhoneValidationSms(phone: string): Promise<SendPhoneValidationSmsResponse>;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<User>();
  const [localCountry, setLocalCountry] = useState<string>();

  const [data, setData] = useState<ConfirmUser>({
    password: '',
    user_email: '',
    code: '',
  });

  useEffect(() => {
    const localToken = localStorage.getItem('@coingoback:token');
    const localExpiration = localStorage.getItem('@coingoback:tokenExpiration');
    const localCountry = localStorage.getItem('@coingoback:localCountry');

    if (localToken && localExpiration) {
      setIsAuthenticated(true);
    }

    if (localCountry) {
      setLocalCountry(localCountry);
    }

    async function getUserData() {
      await getUser();
    }

    if (localToken && localExpiration) {
      getUserData();
    }
  }, []);

  const getIsAuthenticated = () => {
    if (isAuthenticated) {
      return true;
    }
    return false;
  };

  const setLocalTokens = (data: LoginResponse) => {
    localStorage.setItem('@coingoback:token', data.access_token);
    localStorage.setItem(
      '@coingoback:tokenExpiration',
      new Date(new Date().getTime() + data.expires_in * 1000)
        .getTime()
        .toString()
    );
    localStorage.setItem('@coingoback:refreshToken', data.refresh_token);
  };

  const login = async ({ username, password }: LoginShortRequest) => {
    return new Promise<LoginResponse>((resolve, reject) => {
      axiosClient
        .post<LoginRequest, AxiosResponse<LoginResponse>>(
          '/api/oauth/token/site',
          {
            grant_type: 'password',
            username,
            password,
          }
        )
        .then(async response => {
          setLocalTokens(response.data);
          await getUser();
          setIsAuthenticated(true);
          resolve(response?.data);
        })
        .catch(error => {
          reject(error.response?.data ? error.response.data : error.message);
        });
    });
  };

  const refreshToken = async (refreshToken: string) => {
    return new Promise<LoginResponse>((resolve, reject) => {
      axiosClient
        .post<any, AxiosResponse<LoginResponse>>('/api/oauth/token/site', {
          grant_type: 'refresh_token',
          refresh_token: refreshToken,
        })
        .then(async response => {
          setLocalTokens(response.data);
          resolve(response?.data);
        })
        .catch(async error => {
          await logout();
          reject(error.response?.data ? error.response.data : error.message);
        });
    });
  };

  const socialLogin = async ({
    provider,
    access_token,
    referral_code,
  }: SocialLoginRequest) => {
    return new Promise<LoginResponse>((resolve, reject) => {
      axiosClient
        .post<LoginRequest, AxiosResponse<LoginResponse>>(
          '/api/oauth/token/site',
          {
            grant_type: 'social',
            provider,
            access_token,
            referral_code,
          }
        )
        .then(async response => {
          setLocalTokens(response?.data);
          await getUser();
          setIsAuthenticated(true);
          resolve(response?.data);
        })
        .catch(error => {
          reject(error.response?.data ? error.response.data : error.message);
        });
    });
  };

  const signup = async ({ name, email, password, referral_code }: SignupRequest) => {
    return new Promise<LoginResponse>((resolve, reject) => {
      axiosClient
        .post<SignupRequest, AxiosResponse<LoginResponse>>('/api/users', {
          name,
          email,
          password,
          referral_code,
        })
        .then(async response => {
          resolve(response?.data);
        })
        .catch(error => {
          reject(error.response?.data ? error.response.data : error.message);
        });
    });
  };

  const getUser = async (): Promise<User> => {
    return new Promise<User>((resolve, reject) => {
      apolloClient
        .query({
          query: gql`
            query {
              user {
                id
                account_id
                name
                email
                is_phone_validated
                type
                verified_at
                lang
                country
                referrer_code
                verified_at
              }
            }
          `,
        })
        .then(async response => {
          const { user: userData } = response?.data;
          setUser(userData);

          if (userData.country != null) {
            setLocalCountry(userData.country);
            localStorage.setItem('@coingoback:localCountry', userData.country);

            if (userData.lang == 'pt-BR') {
              i18n.changeLanguage('pt-BR')
            } else {
              i18n.changeLanguage('en')
            }
          } else {
            setLocalCountry(undefined);
            localStorage.removeItem('@coingoback:localCountry');
          }

          resolve(userData);
        })
        .catch(error => {
          reject(error.response?.data ? error.response.data : error.message);
        });
    });
  };

  const parseJwt = (token: string): JWT => {
    const base64Url = token?.split('.')[1];
    const base64 = base64Url?.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  };

  const deleteToken = async (token: string) => {
    return new Promise<void>((resolve, reject) => {
      if (!token) {
        return;
      }

      const { jti } = parseJwt(token);

      axiosClient
        .delete(`/api/oauth/tokens/${jti}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(async response => {
          resolve(response?.data);
        })
        .catch(error => {
          reject(error.response?.data ? error.response.data : error.message);
        });
    });
  };

  const deleteUser = async () => {
    return new Promise<void>((resolve, reject) => {
      const token = localStorage.getItem('@coingoback:token');

      axiosClient
        .delete(`/api/users`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(async response => {
          await logout(true);
          resolve(response?.data);
        })
        .catch(error => {
          reject(error.response?.data ? error.response.data : error.message);
        });
    });
  };

  const logout = async (notDeleteAPIToken?: boolean) => {
    const token = localStorage.getItem('@coingoback:token');

    localStorage.removeItem('@coingoback:token');
    localStorage.removeItem('@coingoback:tokenExpiration');
    localStorage.removeItem('@coingoback:refreshToken');

    if (!notDeleteAPIToken) {
      await deleteToken(token);
    }

    setUser(undefined);
    setLocalCountry(undefined);
    setIsAuthenticated(false);
  };

  const updateAuth = async () => {
    await getUser();
    setIsAuthenticated(true);
  };

  const setConfirmEmail = async (values: ConfirmUser) => {
    setData(prevValues => ({
      ...prevValues,
      ...values,
    }));
  };

  const confirmEmail = async ({ user_email, code }: ConfirmUser) => {
    return new Promise<void>((resolve, reject) => {
      axiosClient
        .post<{ user_email: string; code: string }, AxiosResponse<void>>(
          `/api/user-confirmation/email`,
          {
            user_email: user_email,
            code: code,
          }
        )
        .then(async response => {
          resolve(response?.data);
        })
        .catch(error => {
          reject(error.response?.data ? error.response.data : error.message);
        });
    });
  };

  const setCountry = async (countryCode: string) => {
    return new Promise<void>((resolve, reject) => {
      const token = localStorage.getItem('@coingoback:token');

      axiosClient
        .put<{ country: string; }, AxiosResponse<void>>(`/api/user/country`,
          {
            country: countryCode
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          }
        )
        .then(async response => {
          resolve(response?.data);
        })
        .catch(error => {
          reject(error.response?.data ? error.response.data : error.message);
        });
    });
  };

  const validatePhone = async (code: string) => {
    return new Promise<ValidatePhoneResponse>((resolve, reject) => {
      const token = localStorage.getItem('@coingoback:token');

      axiosClient.post<{ country: string; }, AxiosResponse<ValidatePhoneResponse>>(`/api/user/phone-validation`,
          {
            code: code
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          }
        )
        .then(async response => {
          resolve(response?.data);
        })
        .catch(error => {
          reject(error.response?.data ? error.response.data : error.message);
        });
    });
  };

  const sendPhoneValidationSms = async (phone: string) => {
    return new Promise<SendPhoneValidationSmsResponse>((resolve, reject) => {
      const token = localStorage.getItem('@coingoback:token');

      axiosClient.post<{ country: string; }, AxiosResponse<SendPhoneValidationSmsResponse>>(`/api/user/phone-validation/send-sms`,
          {
            phone: phone
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          }
        )
        .then(async response => {
          resolve(response?.data);
        })
        .catch(error => {
          reject(error.response?.data ? error.response.data : error.message);
        });
    });
  };

  return (
    <AuthContext.Provider
      value={{
        getIsAuthenticated,
        login,
        logout,
        signup,
        socialLogin,
        user,
        deleteUser,
        refreshToken,
        updateAuth,
        data,
        confirmEmail,
        setConfirmEmail,
        localCountry,
        setCountry,
        validatePhone,
        sendPhoneValidationSms,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export { AuthContext, useAuth };
