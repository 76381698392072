import styled from 'styled-components';

export const Container = styled.div`
  background-color: #FE501C;
  display: flex;
  padding: 0 40px;

  @media (max-width: 769px) {
    padding: 0 20px;
    margin-top: -20px;
    padding-top: 20px;
  }

  .content {
    width: 100%;
    padding: 8px 0;

    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 769px) {
      flex-direction: column;
    }

    .text {
      p {
        color: #000;
      }

      .title {
        font-size: 18px;
        font-weight: bold;
      }

      @media (max-width: 769px) {
        margin-bottom: 12px;
      }
      cursor: pointer;
    }

    .actions {
      display: flex;

      Button {
        height: 40px;
        width: auto;
        padding: 10px 20px;
        margin: 0 6px;
      }

      button.secondary {
        background: #fff;
        box-shadow: #000 4px 4px 0px -1px, #000 4px 4px;
        transition: box-shadow 0.08s ease-in-out, transform 0.08s ease-in-out;

        &:hover,
        &:focus {
          opacity: 0.9;
          outline: 0;
        }

        &:active {
          box-shadow: none !important;
          transform: translate3d(4px, 4px, 0px);
        }

        img {
          margin-right: 6px;
        }
      }
    }
  }
`;
