import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
`;

export const PageContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  margin: 0 auto;
`;

export const ScrollContent = styled.div`
  min-height: calc(100vh - 80px);
  transition: all 200ms ease-in;

  @media (min-width: 770px) {
    margin-top: 132px;
  }
`;
