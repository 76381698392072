import styled from 'styled-components';

export const Container = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 22px;
  padding: 22px 22px 0 22px;
  margin: 20px 0;
`;

export const Row = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e6e6e6;
  & :first-child {
    margin-left: 0;
  }
  & :last-child {
    margin-right: 0;
    border-bottom: none;
    justify-content: center;
  }
`;

export const Item = styled.div`
  margin: 0 10px;
  min-width: 150px;

  @media (max-width: 380px) {
    min-width: 100px;
  }
`;

export const ItemText = styled(Item)`
  font: normal normal bold 16px/35px Rubik;
  letter-spacing: 0px;
  color: #525252;
  & a {
    color: #525252;
    text-decoration: none;
    cursor: pointer;
  }
  & a:hover {
    color: #888;
    cursor: pointer;
  }
`;

export const ItemTextSmall = styled.div`
  font: normal normal 10px Rubik;
  letter-spacing: 0px;
  color: #525252;
`;
