import styled from 'styled-components';

export const Container = styled.div`
  margin: 40px 0;

  .bg-skeleton {
    width: 100%;
    height: 200px;
    border-radius: 22px;
  }
`;
